import { Button } from 'antd'
import React from 'react'

import { CenterData, CenterStatus, availableCountries } from '../../../../requests'
import { DatacIcon, DatacTooltip, scopedIntlType } from '../../../common'

const renderWithTooltip = (content: string) => (
  <DatacTooltip title={content}>
    <span>{content}</span>
  </DatacTooltip>
)

const ArchiveCenterCell: React.FC<{ onArchive?: () => void }> = ({ onArchive }) => (
  <Button className="datac-button--icon-only" onClick={onArchive}>
    <DatacIcon name="trash" />
  </Button>
)

interface GetColumnsOptions {
  columnNames: {
    name: string
    country: string
    abbreviation: string
    actions: string
  }
  onArchive: (center: CenterData) => void
}
export const getColumns = ({ columnNames, onArchive }: GetColumnsOptions) => {
  return [
    {
      title: 'centerId',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '0'
    },
    {
      title: columnNames.name,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      render: renderWithTooltip
    },
    {
      title: columnNames.country,
      dataIndex: 'country',
      key: 'country',
      ellipsis: true,
      sorter: true,
      render: renderWithTooltip
    },
    {
      title: columnNames.abbreviation,
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      ellipsis: true,
      sorter: true,
      render: renderWithTooltip
    },
    {
      className: 'centers-table__action-column',
      title: columnNames.actions,
      dataIndex: 'status',
      key: 'archiveAction',
      width: '10rem',
      render: (status: string, center: CenterData) =>
        status !== CenterStatus.Archived && <ArchiveCenterCell onArchive={() => onArchive(center)} />
    }
  ]
}

export const searchAndFilterOptions = (countries: string[], intl: scopedIntlType) => {
  const intlCenters = (id: string) => intl(`settings.centers.${id}`)

  return {
    searchInputPlaceholder: intl('common.search'),
    filters: [
      {
        label: intlCenters('country.header'),
        name: 'country',

        options: countries.map(country => ({
          value: country,
          label: availableCountries[country]
        }))
      },
      {
        label: intlCenters('status.header'),
        name: 'status',
        options: [
          {
            value: CenterStatus.Active,
            label: intlCenters('status.active')
          },
          {
            value: CenterStatus.Archived,
            label: intlCenters('status.archived')
          }
        ]
      }
    ]
  }
}
